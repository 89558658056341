<template>
  <div class="web_box">
    <van-nav-bar
      title="关于我们"
      left-arrow
      @click-left="toBack()"
      fixed
      class="header_class"
      :border="false"
    >
      <div class="back_icon" slot="left" />
    </van-nav-bar>

    <div class="content">
      <div class="list">
        <draggable class="list" v-model="list" @end="draggableEnd" :delay="150">
          <div class="item" v-for="item in list" :key="item.id">
            <div class="left">
              <img src="../assets/logistics/icon.png" alt="" />
            </div>
            <div class="right">
              <div class="title">{{ item.touchclass }}{{ item.id }}</div>
              <div class="dis">
                {{ item.name }}
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          name: "协会-省医疗行业资讯管理平台网页设计协会1",
          touchclass: "unmover",
        },
        {
          id: 2,
          name: "协会-省医疗行业资讯管理平台网页设计协会2",
          touchclass: "unmover",
        },
        {
          id: 3,
          name: "协会-省医疗行业资讯管理平台网页设计协会33",
          touchclass: "unmover",
        },
        {
          id: 4,
          name: "协会-省医疗行业资讯管理平台网页设计协会4",
          touchclass: "unmover",
        },
        {
          id: 5,
          name: "协会-省医疗行业资讯管理平台网页设计协会5",
          touchclass: "unmover",
        },
        {
          id: 6,
          name: "协会-省医疗行业资讯管理平台网页设计协会6",
          touchclass: "unmover",
        },
        {
          id: 7,
          name: "协会-省医疗行业资讯管理平台网页设计协会7",
          touchclass: "unmover",
        },
        {
          id: 8,
          name: "协会-省医疗行业资讯管理平台网页设计协会8",
          touchclass: "unmover",
        },
        {
          id: 9,
          name: "协会-省医疗行业资讯管理平台网页设计协会9",
          touchclass: "unmover",
        },
        {
          id: 10,
          name: "协会-省医疗行业资讯管理平台网页设计协会10",
          touchclass: "unmover",
        },
      ],
    };
  },
  components: {
    draggable,
  },

  methods: {
    draggableEnd() {
      console.log(this.list, "结束拖拽");
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.content {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 95%;
  padding: 120px 0 0;
  margin: 0 auto;
  // text-indent: 2em;
  text-align: justify;
}
.list {
  .item {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 40px 0;
    .left {
      width: 120px;
      height: 120px;
      display: flex;
      justify-content: left;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      flex: 1;
      margin-left: 20px;
      .title {
        font-size: 28px;
        color: #00ccff;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .dis {
        color: #8cbcd6;
        font-size: 28px !important;
      }
    }
  }
}
</style>
